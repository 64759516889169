import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

function SEO() {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const {
          title,
          description,
          author,
          image,
          keywords,
        } = data.contentfulHomePage.seo

        const canonical = data.site.siteMetadata.siteUrl

        return (
          <Helmet
            htmlAttributes={`en`}
            title={title}
            titleTemplate={`${title}`}
            link={
              canonical
                ? [
                    {
                      rel: "canonical",
                      href: canonical,
                    },
                  ]
                : []
            }
            meta={[
              {
                name: `description`,
                content: description,
              },
              {
                name: "keywords",
                content: keywords.join(","),
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:creator`,
                content: author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: description,
              },
            ].concat(
              image
                ? [
                    {
                      property: "og:image",
                      content: image.fixed.src,
                    },
                    {
                      property: "og:image:width",
                      content: "1200",
                    },
                    {
                      property: "og:image:height",
                      content: "630",
                    },
                    {
                      name: "twitter:card",
                      content: "summary_large_image",
                    },
                  ]
                : [
                    {
                      name: "twitter:card",
                      content: "summary",
                    },
                  ]
            )}
          />
        )
      }}
    />
  )
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulHomePage {
      seo {
        title
        author
        keywords
        description
        image {
          fixed(resizingBehavior: SCALE, height: 630, width: 1200) {
            src
          }
        }
      }
    }
  }
`
