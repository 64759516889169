import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import { FaLinkedinIn, FaInstagram } from "react-icons/fa"

const Footer = styled.footer`
  background-color: #4a9999;
  color: #ffffff;
  height: 80px;
  display: flex;
  align-items: center;
`

const Text = styled.div`
  flex: 1;
  align-items: center;
`

const SocialLink = styled.a`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  & :hover {
    text-decoration: underline;
  }
  & > svg {
    vertical-align: bottom;
  }
`

export default () => {
  return (
    <Footer>
      <StaticQuery
        query={graphql`
          query FooterQuery {
            contentfulHomePage {
              footer {
                linkedin
                instagram
              }
            }
          }
        `}
        render={data => {
          const { linkedin, instagram } = data.contentfulHomePage.footer

          return (
            <Text>
              <SocialLink href={linkedin}>
                For professional stuff <FaLinkedinIn size={"20px"} />
              </SocialLink>{" "}
              |{" "}
              <SocialLink href={instagram}>
                For cooking & dogs <FaInstagram size={"20px"} />
              </SocialLink>
            </Text>
          )
        }}
      />
    </Footer>
  )
}
