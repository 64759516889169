import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"

const Container = styled.div`
  flex: 1;
  text-align: center;
  padding-top: 50px;
`

const Card = styled.div`
  display: inline-block;
`

const Description = styled.div`
  line-height: 24px;
`

export default function ProfileCard({ avatar, name, description }) {
  return (
    <Container>
      <Card>
        <img src={`${avatar + "&r=max"}`} alt={`${name}s avatar`} />
        <h2>{name}</h2>
        <Description>
          <ReactMarkdown source={description} />
        </Description>
      </Card>
    </Container>
  )
}
