import React from "react"
import styled from "styled-components"

const ButtonContainer = styled.div`
  display: inline-block;
  background-color: #0f6b6b;
  padding: 14px 28px;
  font-family: "Crushed", cursive;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  transition: 0.3s;
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  cursor: pointer;
  &:hover {
    color: #ffffff;
    background-color: #004040;
  }
  &:active {
    -webkit-box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.57);
    transform: translateY(2px) translateX(-2px);
  }
`

export default function Button({ title, onClick }) {
  return <ButtonContainer onClick={onClick}>{title}</ButtonContainer>
}
